<template>
  <div class="prescriptionConfig">
    <div class="specType">
      <el-button type="primary" @click="addSpecType"> 添加规范类型 </el-button>
      <template v-if="isShowSpecType">
        <div class="specTypeList">
          <div class="title">规范类型</div>
          <div v-for="(item, index) in specTypeList" :key="item.id">
            <div class="case">
              <div class="list" @click="viewSpecDetail(item)">
                {{ item.specTypeName }}
              </div>
              <div>
                <i
                  v-if="index !== 0"
                  class="el-icon-upload2"
                  @click="moveUp(item, index)"
                />
                <i
                  v-if="
                    index !== specTypeList.length - 1 &&
                    specTypeList.length !== 1
                  "
                  class="el-icon-download"
                  @click="moveDown(item, index)"
                />
                <i class="el-icon-edit" @click="editSpecType(item)" />
                <i class="el-icon-delete" @click="deleteSpecType(item)" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <el-dialog
      :title="titleType + '规范类型'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form
        v-if="dialogVisible"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <h3 v-if="isDelete" style="margin-bottom: 20px; text-align: center">
          确认删除<span style="color: red">{{ specTypeInfo.specTypeName }}</span
          >信息吗？
        </h3>
        <template v-if="!isDelete">
          <el-form-item label="处方标准名称:">
            <el-select
              v-model="form1.prescriptionSpecCode"
              :disabled="titleType === '修改'"
            >
              <el-option
                v-for="item in specList"
                :key="item.prescriptionSpecCode"
                :value="item.prescriptionSpecCode"
                :label="item.prescriptionSpecName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="处方规范类型编码:" prop="specTypeCode">
            <el-input
              v-model="form1.specTypeCode"
              v-check-permission
              placeholder="请输入处方规范类型编码"
              style="width: 80%"
              :disabled="titleType === '修改'"
              maxlength="10"
              @keyup.enter.native="saveSpecType()"
            />
          </el-form-item>
          <el-form-item label="处方规范类型名称:" prop="specTypeName">
            <el-input
              v-model="form1.specTypeName"
              v-check-permission
              placeholder="请输入处方规范类型名称"
              style="width: 80%"
              maxlength="20"
              @keyup.enter.native="saveSpecType()"
            />
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSpecType()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import tinymc from '@/views/privacyPolicy/tinymcCom';
import { codeRule } from '@/utils/verificationRule';
import { mapState } from 'vuex';
export default {
  name: 'SpecTye',
  props: {
    specTypeList: {
      type: Array,
      default: () => [],
    },
    isShowSpecType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      // isClick:false,
      titleType: '',
      editId: '', // 编辑id
      specTypeInfo: {},
      isDelete: false, // 删除状态
      form1: {
        specTypeCode: '',
        specTypeName: '',
        prescriptionSpecCode: '',
      },
      rules: {
        specTypeName: [
          {
            required: true,
            message: '请输入处方规范类型名称',
            trigger: 'blur',
          },
        ],
        specTypeCode: [
          { required: true, validator: codeRule, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState('prescriptionSpec', {
      specList: (state) => state.specList,
    }),
  },
  watch: {},
  mounted() {},
  methods: {
    // 新增处方类型规范
    addSpecType() {
      this.dialogVisible = true;
      this.isDelete = false;
      this.titleType = '新增';
      this.form1 = {
        specTypeCode: '',
        specTypeName: '',
        prescriptionSpecCode: '',
      };
    },
    // 编辑处方规范
    editSpecType(item) {
      this.dialogVisible = true;
      this.isDelete = false;
      this.titleType = '修改';
      this.form1 = {
        specTypeCode: item.specTypeCode,
        specTypeName: item.specTypeName,
        prescriptionSpecCode: item.prescriptionSpecCode,
      };
      this.specTypeInfo = item;
    },
    deleteSpecType(item) {
      this.dialogVisible = true;
      this.isDelete = true;
      this.titleType = '删除';
      this.specTypeInfo = item;
    },
    // 保存处方规范
    saveSpecType() {
      console.log(this.specTypeInfo, '22', this.form1);
      const { form1, titleType, specTypeInfo, isDelete } = this;
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const param = {
            ...form1,
            // prescriptionSpecCode:specTypeInfo.prescriptionSpecCode
          };
          if (titleType !== '新增') {
            param.id = specTypeInfo.id;
          }
          isDelete ? this.del(param) : this.editOrAdd(param);
        } else {
          return false;
        }
      });
    },
    // 规范移动
    move(item, nextItem, type) {
      const param = {
        id: item.id,
        updateId: nextItem.id,
        updateWeight: nextItem.weight,
        weight: item.weight,
      };
      this.$api.specTypeMoveUpOrDown(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(type === 0 ? '上移成功' : '下移成功');
          this.$emit('refreshTypeList', this.form1.prescriptionSpecCode);
        }
      });
    },
    // 上移
    moveUp(item, index) {
      const { specTypeList } = this;
      const nextItem = specTypeList[index - 1];
      this.move(item, nextItem, 0);
    },
    moveDown(item, index) {
      const { specTypeList } = this;
      const nextItem = specTypeList[index + 1];
      this.move(item, nextItem, 1);
    },
    // 编辑或新增
    editOrAdd(param) {
      this.$api.editSpecType(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(
            this.titleType === '修改' ? '修改处方规范成功' : '新增处方规范成功'
          );
          (this.dialogVisible = false),
            this.$emit('refreshTypeList', this.form1.prescriptionSpecCode);
        }
      });
    },
    // 删除
    del(param) {
      this.$api.deleteSpecType(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('删除处方规范字典成功');
          (this.dialogVisible = false),
            this.$emit('refreshTypeList', this.form1.prescriptionSpecCode);
        }
      });
    },
    // 查看处方类型明细
    viewSpecDetail(item) {
      // this.isClick = true;
      console.log(item, 'item1');
      this.$emit('viewSpecDetail', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.specType {
  display: flex;
  flex-direction: column;
  align-items: center;
  .specTypeList {
    width: 400px;
    margin-top: 10px;
    border: 1px solid #c0c4cc;
    .title {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #fff;
    }
  }
}
.case {
  display: flex;
  justify-content: space-between;
  // margin:0px 20px;
  padding: 0px 10px;
  line-height: 40px;
  border-bottom: 1px solid #c0c4cc;
}
.list {
  width: 300px;
}
.changeColor {
  background-color: aqua;
}
</style>
