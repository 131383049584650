// 不准输入中文
export function codeRule(rule, value, callback) {
  const codeReg = /[\u4E00-\u9FA5]/g;
  if (!value) {
    return callback(new Error('编码不能为空'));
  }
  setTimeout(() => {
    if (!codeReg.test(value)) {
      callback();
    } else {
      callback(new Error('编码不能输入汉字'));
    }
  }, 100);
}
//  简称+数字
export function nameVerification(rule, value, callback) {
  const nameRex = /^[a-zA-Z][a-zA-Z0-9_]{1,30}$/;
  if (!value) {
    return callback(new Error('请输入编码'));
  }
  setTimeout(() => {
    if (nameRex.test(value)) {
      callback();
    } else {
      callback(new Error('编码格式字母开头,允许字母数字下划线'));
    }
  }, 100);
}
// 电话号码校验规则
export function checkPhone(rule, value, callback) {
  const phoneReg = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/;
  if (value) {
    setTimeout(() => {
    // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
    // 所以我就在前面加了一个+实现隐式转换

    if (phoneReg.test(value)) {
      callback();
    } else {
      callback(new Error('电话号码格式不正确'));
    }
  }, 100);
  }else{
    callback();
  }

}
// 身份证校验规则
export function checkIdCard(rule, value, callback) {
  if (!value) {
    return callback(new Error('请输入身份证号码'));
  }
  // 15位和18位身份证号码的正则表达式
  var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

  // 如果通过该验证，说明身份证格式正确，但准确性还需计算
  if (regIdCard.test(value)) {
    if (value.length === 18) {
      var idCardWi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 将前17位加权因子保存在数组里
      var idCardY = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
      var idCardWiSum = 0; // 用来保存前17位各自乖以加权因子后的总和
      for (var i = 0; i < 17; i++) {
        idCardWiSum += value.substring(i, i + 1) * idCardWi[i];
      }
      var idCardMod = idCardWiSum % 11;// 计算出校验码所在数组的位置
      var idCardLast = value.substring(17);// 得到最后一位身份证号码
      // 如果等于2，则说明校验码是10，身份证号码最后一位应该是X
      if (idCardMod === 2) {
        if (idCardLast === 'X' || idCardLast === 'x') {
          return callback();
        } else {
          return callback(new Error('身份证号码错误'));
        }
      } else {
        // 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
        if (Number(idCardLast) === idCardY[idCardMod]) {
          return callback();
        } else {
          return callback(new Error('身份证号码错误'));
        }
      }
    }
    return callback();
  }
  return callback(new Error('身份证号码格式不对'));
}
// 图片验证
export function valiIcon(rule, value, callback) {
  if (!this.newTaskData.logoUrl) {
    callback(new Error('请上传图片'));
  } else {
    callback();
  }
}
 // 价格校验，可以带2位小数点
 export function numRule(rule, value, callback) {
  const nameRex = /^\d+(\.\d{2})?$/;
  const { min = 0, max = 999 } = rule;
  if (!value && value !== 0) {
    return callback(new Error('请输入数值'));
  }
  setTimeout(() => {
    if (nameRex.test(value) && Number(value) <= max && Number(value) >= min) {
      callback();
    } else if(Number(value) > max || Number(value) < min) {
      callback(new Error(`数值只能为数字范围为${min}-${max}`));
    }else {
      callback(new Error('数值只能为数字或者包含2位小数'));
    }
  }, 100);
}
// 价格校验，可以带小数点
export function numPoint(rule, value, callback) {
 const nameRex = /^\d+(\.\d{1,2})?$/;
 if (!value && value !== 0) {
   return callback(new Error('请输入数值'));
 }
 setTimeout(() => {
   if (nameRex.test(value)) {
     if(Number(value) < 0) {
       callback(new Error('数值非负数'));
     }else{
       callback();
     }

   }else {
     callback(new Error('数值只能为数字或者包含小数'));
   }
 }, 100);
}
// 输入纯数字
export function onlyNumber(rule, value, callback) {
  if(value !== '') {
      if((/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(value) === false) {
          callback(new Error('请填写大于0的数字'));
      }else{
          callback();
      }
  }else{
      callback();
  }

}
// 号源数校验，
export function sourceNumRule(rule, value, callback) {
  if(value !== '') {
      if((/\D/g).test(value)) {
          callback(new Error('请输入1-30的数字'));
      }else {
        if(value > 0 && value <= 30) {
           callback();
        }else{
          callback(new Error('请输入1-30的数字'));

        }

      }
  }else{
      callback();
  }

}
// 百分数
export function percentNum(rule, value, callback) {
  const nameRex = /^\d+(\.\d{2})?$/;
  if (!value && value !== 0) {
    return callback(new Error('请输入数值'));
  }
  setTimeout(() => {
    if (nameRex.test(value) && Number(value) <= 100 && Number(value) >= 0) {
      callback();
    } else if(Number(value) > 100 || Number(value) < 0) {
      callback(new Error('数值只能为数字范围为0-100'));
    }else {
      callback(new Error('数值只能为数字或者包含2位小数'));
    }
  }, 100);
}
// 只能输入甲乙
export function onlyAB(rule, value, callback) {
  if(value !== '' && value) {
      if(value !== '甲' && value !== '乙') {
          callback(new Error('请填写甲或乙'));
      }else{
          callback();
      }
  }else{
      callback();
  }

}
// 手机号码校验规则
export function checkMobile(rule, value, callback) {
  const phoneReg = /^1[0-9]{10}$/;
  if (!value) {
    return callback(new Error('电话号码不能为空'));
  }
  setTimeout(() => {
    // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
    // 所以我就在前面加了一个+实现隐式转换

    if (!Number.isInteger(+value)) {
      callback(new Error('请输入数字值'));
    } else {
      if (phoneReg.test(value)) {
        callback();
      } else {
        callback(new Error('电话号码格式不正确'));
      }
    }
  }, 100);
}

export function positiveInteger(rule, value, callback) {
  console.log(value, 'value');
  const nameRex = /^[1-9][0-9]*$/;
  // if (!value) {
  //   return callback(new Error('字段不能为空'));
  // }
  setTimeout(() => {
    if (nameRex.test(value)) {
      callback();
    } else {
      callback(new Error('请输入大于0的正整数'));
    }
  }, 100);
}

// 序号校验
export function serialNumRule(rule, value, callback) {
  const nameRex = /^\d+$/;
  const { min = 0, max = 999 } = rule;
  if (!value && value !== 0) {
    return callback(new Error('请输入数值'));
  }
  setTimeout(() => {
    if (nameRex.test(value) && Number(value) <= max && Number(value) >= min) {
      callback();
    } else if(Number(value) > max || Number(value) < min) {
      callback(new Error(`数值只能为数字范围为${min}-${max}`));
    }else {
      callback(new Error('数值只能为数字'));
    }
  }, 100);
}
